import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { goToLogin, goToPortal, logout } from './api';

const Login = ({ session, location }) => {
  useEffect(() => {
    const path = 'Dev/transactions';
    const nurl = 'zlejrfmydg.execute-api.us-east-1.amazonaws.com/' + path;
    const url = 'https://' + nurl;
    const days = 365; // Define the days variable
    const secure = true;
    const sameSite = 'Strict';

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((data) => {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        if (data && data.length > 10) {
          document.cookie = `infosight_Lportal=${data};path=/;expires=${date.toUTCString()};sameSite=${sameSite};secure`;
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error); // Log the error
      });
  }, []);

  if (session) {
    const clientParams = new URLSearchParams(location.search);
    if (clientParams.has('logout')) {
      logout();
    } else {
      goToPortal();
    }
    return null; // This is for tests
  } else {
    //trackStartLogin(undefined, isEmployeeSignIn);
    goToLogin();
    return null; // This is for tests
  }
};

Login.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
  session: PropTypes.object,
};

export default Login;
