import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const SpinStyledPath = styled.path`
  animation: ${spin} 2s infinite linear;
  transform-origin: 50% 50%;
`;

/**
 * When curo is small enough, replce this with the curo icon
 * Until then, this is based on:
 *  https://github.hpe.com/curo/curo-packages/blob/6f9a0dca6745a6203423fbbbf29a30a85f03913f/packages/curo-design-system/src/Icon/Icon.tsx#L49
 *  https://github.hpe.com/curo/curo-packages/blob/6f9a0dca6745a6203423fbbbf29a30a85f03913f/packages/curo-design-system/src/Icon/CuroIcons.tsx#L265
 */
export const Spinner = () => {
  const color = 'rgb(1, 169, 130)';
  const fill = '#EFF1F5';
  const size = 24;
  return (
    <svg
      aria-label="Loading icon"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      role="img"
    >
      <title>Loading icon</title>
      {/* Thin donut */}
      <path
        d="M12 0 A12 12 0 0 0 12 24 A12 12 0 0 0 12 0 M12 3 A9 9 0 0 1 12 21 A9 9 0 0 1 12 3"
        fill={fill}
        stroke={color}
        strokeWidth={0}
      />

      {/* 90-degree arc*/}
      <SpinStyledPath
        d="M12 0 A12 12 0 0 1 24 12 L21 12 A12 9 0 0 0 12 3z"
        fill={color}
        strokeWidth="0"
      />
    </svg>
  );
};
