import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './Login';
import Eula from './Eula';
import EmailVerification from './EmailVerification';
import ErrorBoundary from './ErrorBoundary';
import { BrowserCheck } from './BrowserCheck';

const basename = window.location.pathname.startsWith('/legacyapp')
  ? '/legacyapp'
  : '/app';
const App = ({ session }) => (
  <BrowserRouter basename={basename}>
    <ErrorBoundary>
      <BrowserCheck>
        <Switch>
          <Route
            path="/login"
            render={(props) => <Login session={session} {...props} />}
          />
          <Route
            path="/termsofuse/view"
            render={(props) => <Eula viewOnly {...props} />}
          />
          <Route
            path="/termsofuse"
            render={(props) => <Eula session={session} {...props} />}
          />
          <Route
            path={['/verifyemail', '/confirmemail/:token']}
            render={(props) => (
              <EmailVerification session={session} {...props} />
            )}
          />
          <Redirect to="/login" push={false} />
        </Switch>
      </BrowserCheck>
    </ErrorBoundary>
  </BrowserRouter>
);

App.propTypes = {
  session: PropTypes.shape({
    emailVerification: PropTypes.oneOf(['VERIFIED', 'PENDING', 'NOT_VERIFIED'])
      .isRequired,
    user: PropTypes.shape({
      userName: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
export default App;
