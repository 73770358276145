import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Text, Anchor } from 'grommet';
import { getEula, acceptEula, getIdPLoginUrl, logout, goToPortal } from './api';
import { useAsyncTask, useHeaderRemover } from './hooks';
import { Spinner } from './Spinner';
import Body from './Body';

const Eula = ({ viewOnly = false, session }) => {
  const [html, setHtml] = useState(null);

  const acceptTask = useAsyncTask(() => acceptEula().then(goToPortal), false);

  useEffect(() => {
    async function doIt() {
      setHtml(await getEula());
    }

    doIt();
  }, [setHtml]);

  useHeaderRemover();

  if (!session && !viewOnly) {
    return logout();
  }

  return (
    <Body title="HPE InfoSight Terms of Use">
      {!html && <Spinner />}
      <div dangerouslySetInnerHTML={{ __html: html }} />
      {!viewOnly && html && (
        <Box
          justify="end"
          gap="small"
          direction="row"
          align="center"
          margin={{ top: 'medium' }}
        >
          {acceptTask.error && (
            <Box>
              <Text color="status-critical">
                There was a problem. You may need to{' '}
                <Anchor href={getIdPLoginUrl(session.user.userName)}>
                  Login again
                </Anchor>
                .
              </Text>
            </Box>
          )}
          {session && (
            <Button
              icon={acceptTask.pending ? <Spinner /> : null}
              disabled={acceptTask.pending}
              label="Accept"
              primary
              onClick={acceptTask.execute}
            />
          )}
          {session && <Button label="Decline" secondary onClick={logout} />}
        </Box>
      )}
    </Body>
  );
};

Eula.propTypes = {
  session: PropTypes.shape({
    user: PropTypes.shape({
      userName: PropTypes.string.isRequired,
    }).isRequired,
  }),
  viewOnly: PropTypes.bool,
};

export default Eula;
