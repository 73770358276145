import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { error, errorInfo } = this.state;
    if (error) {
      return (
        <div className="error-boundary">
          <div>
            <h2>Error: {error.message || error.toString()}</h2>
            <pre>{error.stack}</pre>
          </div>
          <br />
          <div>
            <h2>Component Stack</h2>
            <pre>
              {errorInfo &&
                errorInfo.componentStack &&
                errorInfo.componentStack.trim()}
            </pre>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
