import { trackEvent } from './analytics';

const IAM_BACKEND_PATH =
  process.env.REACT_APP_IAM_BACKEND_PATH || '/InfoSight/api/iam';

/**
 * Accept the window object to make testing sane.
 * Exported only for testing
 */
export const determineTargetUrl = (
  { location, document } = window,
  depth = 0
) => {
  // Prevent infinite recursion by bailing
  if (depth >= 3) {
    return null;
  }
  const clientParams = new URLSearchParams(location.search);

  let target;
  // This is here for backward-compatibility. The portal does not use it.
  const redirectTo = clientParams.get('redirectTo');
  if (redirectTo) {
    target = redirectTo;
  } else if (
    // If browser has same-domain referer, use it
    // E.g. If the user typed infosight.hpe.com into the address bar, there is no referer
    // E.g, If the portal redirects here, the referer will be the either the base address or the deep URL to some page
    // E.g. If the user clicked a link to IS on a PointNext Support page or YouTube, the referer is from a different domain, so ignore it
    document.referrer &&
    document.referrer.startsWith(location.origin)
  ) {
    target = document.referrer;
  } else {
    target = location.origin;
  }

  const targetLocation = new URL(target, location.origin);
  if (targetLocation.pathname === location.pathname) {
    target = determineTargetUrl(
      {
        location: targetLocation,
        document: window.document,
      },
      depth + 1
    );
  }

  return target;
};

const graphql = async (
  { query, variables },
  { redirectOn401 } = { redirectOn401: false }
) => {
  const response = await fetch(`${IAM_BACKEND_PATH}/graphql`, {
    method: 'POST',
    body: JSON.stringify({
      query,
      variables: variables ? JSON.stringify(variables) : null,
    }),
    credentials: 'same-origin',
  });

  if (redirectOn401 && response.status === 401) {
    return logout();
  }

  if (response.ok) {
    const result = await response.json();
    if (result.errors) {
      console.error('graphql errors', result.errors);
    }

    return result;
  }

  throw new Error(`Request failed: ${response.status} ${response.statusText} `);
};

// const ipAddress = async () => {
//   const response = await fetch(`https://api.ipify.org/?format=json`);
//   if (!response.ok) {
//     throw new Error(response.statusText);
//   }
//   const data = await response.json();
//   if (data && data.ip) {
//     return data.ip;
//   }
//   return false;
// };

// export const getIPAddress = async () => {
//   return await ipAddress();
// };

export const getSession = async () => {
  const { data } = await graphql({
    query:
      'query { currentSession { emailVerification eulaAccepted user { userName } } }',
  });

  if (data && data.currentSession) {
    return data.currentSession;
  }

  throw new Error('No active session');
};

export const getEula = async () => {
  const response = await fetch(`${IAM_BACKEND_PATH}/eula`);
  return response.ok ? await response.text() : null;
};

export const acceptEula = async () => {
  const { data } = await graphql({
    query: 'mutation acceptEula { acceptEula }',
  });

  if (data && data.acceptEula) {
    return data.acceptEula;
  }

  throw new Error('Request failed.');
};

export const checkEmailVerification = async (token) => {
  const { data, errors } = await graphql(
    {
      query:
        'mutation checkEmailVerification($token: String) { checkEmailVerification(jwt: $token) }',
      variables: { token },
    },
    { redirectOn401: true }
  );

  if (data && data.checkEmailVerification) {
    return true;
  }
  if (errors) {
    const error = errors.find(
      ({ path: [key] }) => key === 'checkEmailVerification'
    );
    if (error) {
      // Remove the extra stuff and just return the user-friendly message
      // Exception while fetching data (/checkEmailVerification) : Invalid or expired token.
      let { message } = error;
      message = message.split(' : ');
      message = message[message.length - 1];
      throw new Error(message);
    }
  }

  throw new Error('Request failed.');
};

export const sendEmailVerification = async () => {
  const { data } = await graphql({
    query: 'mutation sendEmailVerification { sendEmailVerification }',
  });

  trackEvent('auth.Send Email Verification');

  if (data && data.sendEmailVerification) {
    return true;
  }

  throw new Error('Request failed.');
};

/**
 * This only exists to make unit testing possible
 * @param url
 */
export const redirectTo = (url) => {
  window.location.assign(url);
};

export const LOGOUT_URL = '/InfoSight/api/iam/saml/logout';

export const logout = () => {
  redirectTo(LOGOUT_URL);
};

export const goToPortal = () => redirectTo('/landing');

export const goToLogin = () => redirectTo(getIdPLoginUrl());

export const getIdPLoginUrl = () => {
  const params = new URLSearchParams();
  const username = 'auto@login.com';
  if (username) {
    params.set('username', username);
  }
  const target = determineTargetUrl();
  if (target) {
    params.set('target', target);
  }
  return `${IAM_BACKEND_PATH}/saml/login?${params.toString()}`;
};
