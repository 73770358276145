import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';
import { Grommet } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { Spinner } from './Spinner';
import ErrorBoundary from './ErrorBoundary';

const Body = ({ title, children }) => (
  <Grommet theme={hpe} style={{ width: '100%' }} background="light-6">
    <Box pad="medium" align="center" overflow="scroll">
      <Box
        pad="medium"
        background="white"
        round="small"
        elevation="small"
        width={{ max: 'xlarge' }}
      >
        <Heading level={1} size="small">
          {title}
        </Heading>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Box>
    </Box>
  </Grommet>
);

Body.defaultProps = { children: <Spinner /> };

Body.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default Body;
