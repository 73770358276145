/**
 * @see https://docs.newrelic.com/attribute-dictionary?attribute_name=&events_tids%5B%5D=8307
 * These attributes names are also default attribute names.
 * In most cases, you may not need to set these attributes if they capture the same information you need.
 *
 * This array was created by copying the table directly from docs into Excel,then copying that column here and using multiline-editing to convert to an object.
 */
const defaultAttributes = [
  'actionName',
  'appId',
  'appName',
  'asn',
  'asnLatitude',
  'asnLongitude',
  'asnOrganization',
  'browserHeight',
  'browserWidth',
  'city',
  'countryCode',
  'currentUrl',
  'deviceType',
  'name',
  'pageUrl',
  'referrerUrl',
  'regionCode',
  'session',
  'timeSinceLoad',
  'timestamp',
  'userAgentName',
  'userAgentOS',
  'userAgentVersion',
];

const debug =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

export const trackEvent = (event, attributes = {}) => {
  if (window.newrelic) {
    const cleanAttributes = Object.entries(attributes).reduce(
      (memo, [key, value]) => {
        const blacklisted = defaultAttributes.some((it) => it === key);
        if (debug) {
          console.assert(
            !blacklisted,
            `attribute "${key}" in event "${event}" is a New Relic PageAction attribute. Choose a different name.`
          );
        }
        return blacklisted ? memo : { ...memo, [key]: value };
      },
      {}
    );

    window.newrelic.addPageAction(event, cleanAttributes);

    if (debug) {
      console.debug(`New Relic tracked event "${event}"`, cleanAttributes);
    }
  }
};
