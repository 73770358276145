import { useState, useCallback, useEffect } from 'react';

/**
 * Based on https://usehooks.com/useAsync/
 * @param asyncFunction
 * @param immediate
 */
export const useAsyncTask = (asyncFunction, immediate = true) => {
  const [status, setStatus] = useState('idle');
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setStatus('pending');
    setValue(null);
    setError(null);

    return asyncFunction()
      .then((response) => {
        setValue(response);
        setStatus('success');
      })
      .catch((error) => {
        setError(error);
        setStatus('error');
      });
  }, [asyncFunction]);

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate && status === 'idle') {
      execute();
    }
  }, [execute, immediate, status]);

  return {
    execute,
    value,
    error: error || status === 'error',
    pending: status === 'pending',
    success: status === 'success',
    idle: status === 'idle',
  };
};

export const useHeaderRemover = () => {
  useEffect(() => {
    try {
      document.getElementById('header').classList.add('hide');
      document.getElementById('footer').classList.add('hide');

      return () => {
        document.getElementById('header').classList.remove('hide');
        document.getElementById('footer').classList.remove('hide');
      };
    } catch (e) {
      // Header and Footer elements do not exist
    }
  }, []);
};
