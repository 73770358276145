import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { getSession } from './api';

const render = (session) => {
  ReactDOM.render(
    <React.StrictMode>
      <App session={session} />
    </React.StrictMode>,
    document.getElementById('content')
  );
};

getSession()
  .then(render)
  .catch(() => render(null));
