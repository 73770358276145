import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Anchor, Button, Paragraph } from 'grommet';
import {
  logout,
  LOGOUT_URL,
  sendEmailVerification,
  checkEmailVerification,
  goToPortal,
} from './api';
import { useAsyncTask, useHeaderRemover } from './hooks';
import { Spinner } from './Spinner';
import Body from './Body';
import { trackEvent } from './analytics';

const EmailVerification = ({
  session,
  match: {
    params: { token },
  },
}) => {
  // If there is a token, this switches to confirm mode. But when we resend the
  // email from the confirmation page, we want to switch over to send/verify mode
  const [confirmMode, setConfirmMode] = useState(!!token);

  useHeaderRemover();

  const sendEmailTask = useAsyncTask(
    sendEmailVerification,
    session && session.emailVerification === 'NOT_VERIFIED'
  );
  const checkEmailTask = useAsyncTask(
    () => checkEmailVerification(token).then(goToPortal),
    confirmMode
  );

  useEffect(() => {
    if (!confirmMode) {
      trackEvent('auth.Verify Email Page Viewed');
    }
  }, [confirmMode]);

  if (!session) {
    logout();
    return null; // This is here to make unit tests happy
  }

  const {
    emailVerification,
    user: { userName },
  } = session;

  let title = 'Verify your email address';
  if (confirmMode && checkEmailTask.error) {
    title = 'Error confirming your email address';
  } else if (confirmMode) {
    title = 'Confirming your email address';
  }

  let sendVerificationMessage;
  if (sendEmailTask.success) {
    sendVerificationMessage = (
      <Paragraph>
        A new verification email has been sent to{' '}
        <Text weight="bold">{userName}</Text>. It will expire in 3 days.&nbsp;
      </Paragraph>
    );
  } else if (emailVerification === 'PENDING' && sendEmailTask.idle) {
    sendVerificationMessage = (
      <Paragraph>
        A verification email was sent to <Text weight="bold">{userName}</Text>
        .&nbsp;
      </Paragraph>
    );
  } else if (checkEmailTask.pending) {
    return (
      <Body title={title}>
        <Spinner />
      </Body>
    );
  }

  return (
    <Body title={title}>
      {!confirmMode && (
        <Box gap="small" direction="column">
          {sendVerificationMessage}
          <Paragraph margin="none">
            If you have not received an email after 15 minutes, try these:
          </Paragraph>
          <ol style={{ margin: '0' }}>
            <li>
              Check the <i>Junk</i> or <i>Spam</i> folder in your inbox.
            </li>
            <li>Click the resend button below to try sending it again.</li>
            <li>
              If you still have problems, email{' '}
              <Anchor href="mailto:infosightsupport@hpe.com?subject=%5BInfoSight%20Portal%20Support%5D%20Email%20Verification">
                infosightsupport@hpe.com
              </Anchor>
              .
            </li>
          </ol>
          {sendEmailTask.error && (
            <Box>
              <Text color="status-critical">
                There was an error.
                <Anchor href={LOGOUT_URL}>Try logging in again.</Anchor>
              </Text>
            </Box>
          )}
        </Box>
      )}
      {confirmMode && checkEmailTask.error && (
        <>
          <Paragraph fill>
            There was an error confirming your email address:{' '}
            <Text weight="bold">{checkEmailTask.error.message}</Text>.
          </Paragraph>
          <Paragraph fill>
            If you logged in with a different email address, you will need to
            log in with the address where you received the verification email.
            Otherwise, your token may have expired, so you should resend the
            email.
          </Paragraph>
        </>
      )}
      <Box
        justify="end"
        gap="small"
        direction="row"
        align="center"
        margin={{ top: 'medium' }}
      >
        {sendEmailTask.pending && <Spinner />}
        <Button
          label="Resend Email"
          secondary
          onClick={() => {
            sendEmailTask.execute();
            if (confirmMode) {
              setConfirmMode(false);
            }
          }}
          disabled={
            sendEmailTask.pending ||
            (confirmMode && (checkEmailTask.pending || checkEmailTask.idle))
          }
        />
        {checkEmailTask.error && (
          <Button
            label="Log In"
            secondary
            onClick={logout}
            disabled={sendEmailTask.pending}
          />
        )}
      </Box>
    </Body>
  );
};

EmailVerification.propTypes = {
  session: PropTypes.shape({
    emailVerification: PropTypes.oneOf(['VERIFIED', 'PENDING', 'NOT_VERIFIED'])
      .isRequired,
    user: PropTypes.shape({
      userName: PropTypes.string.isRequired,
    }).isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default EmailVerification;
